module.exports = [{
      plugin: require('../plugins/segment/gatsby-browser.js'),
      options: {"plugins":[],"writeKey":"5snygedwXuQcdHuXW8jdOEVRqUiQelKa","trackPage":false,"ccpaLibURL":"https://calstate.aaa.com/privacy-rights/v1/lib","ccpaApiURL":"https://calstate.aaa.com/endpoints/privacy-rights/v1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Smarthome Welcome Kit","short_name":"SH Welcome Kit","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"standalone","icon":"src/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"82411f614eb8345e307055b86c68fdcc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params-v2/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"disableBgImage":true,"maxWidth":2880,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
